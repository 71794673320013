<template>
  <div>
    <span v-if="showAsRow">Followers</span>
    <div class="v-row__followers">
      <div
        class="v-row__followers-amount mt-2 followers-length">
        <p class="mb-0">
          {{ renderedFollowersAmount }}
        </p>
      </div>
      <Members
        v-if="followers.length"
        is-filter-last-members
        :disabled-tooltip="!premiumGroup"
        :hide-avatar="!premiumGroup"
        :count-visible-members="10"
        overflow-dots
        class="mt-3"
        :members="followers" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Members from '@/components/App/AppTable/AppTableMembers';
import followersAmount from '@/mixins/FollowersAmount';
export default {
  name: 'AppFollowersList',
  components: {
    Members,
  },
  mixins: [followersAmount],
  props: {
    followers: {
      type: Array,
      default: () => [],
    },
    showAsRow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['premiumGroup']),
  },
};
</script>
<style scoped lang="scss">
  .v-row__followers {
    height: 44px;
    display: flex;
    &-amount {
      margin-right: 12px;
      align-self: end;
      font-size: 18px;
    }
  }
</style>
