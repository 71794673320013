<template>
  <div class="info-block">
    <div class="d-flex justify-center">
      <AppDragAndDropArea
        :aspect-ratio="3/2"
        :file="fileForPreview"
        :switcher-bottom-text="textCrop"
        is-avatar
        switcher-crop
        upload-logo-file
        @uploadFile="saveLogo" />
      <file-upload
        ref="uploadLogo"
        v-model="logos"
        :disabled="disabled"
        :multiple="false"
        :drop="false"
        :drop-directory="false"
        accept="image/*"
        input-id="logo"
        @input-filter="inputFilter" />

      <div class="badge-cirle-container">
        <v-icon
          v-if="!disabled && activeHeader.logo"
          class="badge-cirle-container__close-icon"
          @click="deleteLogo">
          mdi-close-circle
        </v-icon>

        <div
          v-if="activeHeader.logo"
          class="creator-logo-wrapper">
          <ListingImage
            :pictures="setPictures(activeHeader)" />
        </div>
        <v-icon
          v-else
          size="98"
          color="blue">
          mdi-image
        </v-icon>

        <label
          v-if="!disabled"
          for="logo"
          class="small-p">
          <div
            class="circle-button-container circle-button-container__add-avatar">
            <v-icon
              color="darkGrey">
              mdi-plus-circle-outline
            </v-icon>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import FileUpload from 'vue-upload-component';
import { v4 as uuid } from 'uuid';
import { Storage } from 'aws-amplify';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import SetPictures from '@/mixins/SetPictures';
import CollectionsApi from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';
import ListingImage from '@/components/Listing/ListingImageItem/ListingImage';
import { cacheControl } from '@/constants/cores';
import AppDragAndDropArea from '@/components/App/AppDragAndDropArea';
export default {
  name: 'Logos',
  components: {
    AppDragAndDropArea,
    FileUpload,
    ListingImage,
  },
  mixins: [ToggleViewCondition, SetPictures],
  props: {
    textCrop: {
      type: String,
      default: '',
    },
    activeHeader: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data: function () {
    return {
      logos: [],
      fileForPreview: null,
    };
  },
  computed: {
    ...mapState(['updatedLogo', 'infoModal']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['openedInfoModal']),
  },
  watch: {
    logos(val) {
      let { file } = val[val.length - 1];
      if (file) {
        this.fileForPreview = file;
        false && this.saveLogo(file);
      }
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      handleError: 'handleError',
      updateActiveListingElement: 'updateActiveListingElement',
      setForUpdateLibraryOrCollection: 'setForUpdateLibraryOrCollection',
    }),
    uploadAvatar(file) {
      console.log(file);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (newFile.file && !newFile.file.type.includes('image')) {
          return prevent();
        }
      }
    },
    async saveLogo(file) {
      this.spinner(true);
      try {
        const id = `${uuid()}/${file.name}`;
        const metadata = {
          workspace_id: this.activeWorkspaceId,
        };
        const { key } = await Storage.put(id, file, {
          contentType: file.type,
          level: 'public',
          metadata,
          cacheControl,
        });
        this.$emit('addLogo', key);
        const variables = {
          ...this.activeHeader,
          logo: key,
        };
        this.infoModal ? this.setForUpdateLibraryOrCollection(variables)
          : this.updateActiveListingElement(variables);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async deleteLogo() {
      this.spinner(true);
      try {
        let variables = {
          libraryId: this.activeHeader.libraryId ? this.activeHeader.libraryId : this.activeHeader.id,
          collectionId: this.activeHeader.libraryId ? this.activeHeader.id : '',
          logo: '',
          workspaceId: this.activeWorkspaceId,
        };
        this.activeHeader.libraryId
          ? await CollectionsApi.update(variables)
          : await LibrariesApi.update(variables);
        this.$emit('addLogo', '');
        const variablesForUpdate = {
          ...this.activeHeader,
          logo: null,
        };
        this.infoModal ? this.setForUpdateLibraryOrCollection(variablesForUpdate)
          : this.updateActiveListingElement(variablesForUpdate);
      } catch (err) {
        this.handleError(err);
      }
      this.spinner(false);
    },
  },
};
</script>
<style scoped lang="scss">
.badge-cirle-container {
  position: relative;

  &__close-icon {
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    transform: translate(50%, -50%);
  }
}
img {
  width: 160px;
  border-radius: 5px;
}
.circle-button-container {
  z-index: 3;
}
.creator-logo-wrapper {
  height: 115px;
  width: 175px;
  overflow: hidden;
}
</style>
