import loader from '@/services/googleApi';
export default {
  data() {
    return {
      autocomplete: null,
    };
  },
  mounted() {
    if (this.initializeGoogleAutocompleteOnMounted) {
      this.initAutocomplete();
    }
  },
  methods: {
    async initAutocomplete() {
      this.google = await loader.load();
      this.$nextTick(() => {
        const autocomplete = new this.google.maps.places.Autocomplete(
          document.getElementById('placeAuto'),
          {
            types: ['(cities)'],
          }
        );
        this.autocomplete = autocomplete;
        autocomplete.addListener('place_changed', this.setPlace);
      });
    },
    setPlace() {
      let place = this.autocomplete.getPlace();
      const administrative_area_level_1 =
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_1')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_1')
                )[0].long_name;
      const administrative_area_level_1ShortName =
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_1')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_1')
                )[0].short_name;
      const county =
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_2')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('administrative_area_level_2')
                )[0].short_name;
      const country =
                place.address_components.filter(el =>
                  el.types.includes('country')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('country')
                )[0].long_name;
      const countryShortName =
                place.address_components.filter(el =>
                  el.types.includes('country')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('country')
                )[0].short_name;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const name = place.name;
      const locality =
                place.address_components.filter(el =>
                  el.types.includes('locality')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('locality')
                )[0].long_name;
      const zipCode =
                place.address_components.filter(el =>
                  el.types.includes('postal_code')
                )[0] &&
                place.address_components.filter(el =>
                  el.types.includes('postal_code')
                )[0].long_name;
      this.additionalAddressData = {
        administrative_area_level_1,
        country,
        lat,
        lng,
        name,
        countryShortName,
        administrative_area_level_1ShortName,
        locality,
        zipCode,
        county,
      };
      this.address = document.getElementById('placeAuto').value;
    },
  },
};
