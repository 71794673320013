<template>
  <Cropper
    v-if="!uploadLogoFile"
    ref="cropper"
    class="cropper"
    :src="imageForCropp"
    :stencil-props="{
      handlers: {},
      movable: false,
      scalable: false,
      aspectRatio: 1,
    }"
    :resize-image="{
      adjustStencil: false
    }"
    image-restriction="stencil"
    @change="change" />
  <Cropper
    v-else
    ref="cropper"
    class="cropper"
    :resize-image="!isNotCrop"
    :move-image="!isNotCrop"
    :image-restriction=" isNotCrop ? 'none' :'fit-area'"
    :image-class=" isNotCrop ? 'hide-bg-crop' : ''"
    :min-width="15"
    :stencil-props="{
      aspectRatio: isNotCrop ? 1 : aspectRatio,
      ...defaultImg
    }"
    :src="imageForCropp"
    @change="change" />
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
export default {
  name: 'AccountCropper',
  components: {
    Cropper,
  },
  props: {
    isNotCrop: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    img: {
      type: String,
      default: null,
    },
    uploadLogoFile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageForCropp: null,
  }),
  computed: {
    defaultImg() {
      return this.isNotCrop ? {
        handlers: {
        },
        lines: {
        },
        movable: false,
        scalable: false,
      } : {
      };
    },
  },
  watch: {
    img: {
      handler(val) {
        this.imageForCropp = val;
      },
      immediate: true,
    },
  },
  methods: {
    change({ canvas }) {
      if (canvas) {
        canvas.toBlob((blob) => {
          this.$emit('croppImage', blob);
        });
      }
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>
