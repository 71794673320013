import { isString } from 'lodash';
import { extractNumberFromString } from '@/utils';
export default {
  props: {
    followersAmount: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    isStringFollowersAmount() {
      return isString(this.followersAmount);
    },
    renderedFollowersAmount() {
      const { isStringFollowersAmount, followersAmount } = this;
      let amount = followersAmount;
      if (isStringFollowersAmount) {
        amount = Number(extractNumberFromString(amount));
      }
      if (amount > 99) {
        return '99+';
      }
      return amount;
    },
  },
};
