var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.uploadLogoFile)?_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.imageForCropp,"stencil-props":{
    handlers: {},
    movable: false,
    scalable: false,
    aspectRatio: 1,
  },"resize-image":{
    adjustStencil: false
  },"image-restriction":"stencil"},on:{"change":_vm.change}}):_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"resize-image":!_vm.isNotCrop,"move-image":!_vm.isNotCrop,"image-restriction":_vm.isNotCrop ? 'none' :'fit-area',"image-class":_vm.isNotCrop ? 'hide-bg-crop' : '',"min-width":15,"stencil-props":Object.assign({}, {aspectRatio: _vm.isNotCrop ? 1 : _vm.aspectRatio},
    _vm.defaultImg),"src":_vm.imageForCropp},on:{"change":_vm.change}})}
var staticRenderFns = []

export { render, staticRenderFns }